import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { PaymentConfirmationComponent } from './components/payment-confirmation/payment-confirmation.component';
import { PaymentMethodComponent } from './pages/payment-method/payment-method.component';
import { AuthGuard } from './_guards/auth.guard';
import { LowercaseUrlGuard } from './_guards/lowerCase.guard';
import { NotFoundComponent } from './components/not-found/not-found.component';

const routes: Routes = [
    { path: '', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
    { path: 'login', canActivate: [AuthGuard], loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
    { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule), data: { title: 'Catholic Legacy' } },
    { path: 'resources', loadChildren: () => import('./pages/resources/resources.module').then(m => m.ResourcesModule) },
    { path: 'about-us', loadChildren: () => import('./pages/about-us/about-us.module').then(m => m.AboutUsModule) },
    { path: 'notification', canActivate: [AuthGuard], loadChildren: () => import('./pages/notification/notification.module').then(m => m.NotificationModule) },
    { path: 'initiate-donation', loadChildren: () => import('./pages/initiate-donation/initiate-donation.module').then(m => m.InitiateDonationModule) },
    { path: 'privacy-policy', loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule) },
    { path: 'terms-of-use', loadChildren: () => import('./pages/terms-of-use/terms-of-use.module').then(m => m.TermsOfUseModule) },
    { path: 'forgot-password', canActivate: [AuthGuard], loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPassowrdModule) },
    { path: 'create-new-password/:token', canActivate: [AuthGuard], loadChildren: () => import('./pages/create-new-password/create-new-password.module').then(m => m.CreateNewPassowrdModule) },
    { path: 'create-account', canActivate: [AuthGuard], loadChildren: () => import('./pages/create-account/create-account.module').then(m => m.CreateAccountModule) },
    { path: 'my-profile', canActivate: [AuthGuard], loadChildren: () => import('./pages/my-profile/my-profile.module').then(m => m.MyProfileModule) },
    { path: 'contact-us', loadChildren: () => import('./pages/contact-us/contact-us.module').then(m => m.ContactUsModule) },
    { path: 'my-address', canActivate: [AuthGuard], loadChildren: () => import('./pages/my-address/my-address.module').then(m => m.MyAddressModule) },
    { path: 'my-files', canActivate: [AuthGuard], loadChildren: () => import('./pages/my-files/my-files.module').then(m => m.MyFilesModule) },
    { path: 'my-forms', canActivate: [AuthGuard], loadChildren: () => import('./pages/my-form/my-form.module').then(m => m.MyFormModule) },
    { path: 'shared-doc', canActivate: [AuthGuard], loadChildren: () => import('./pages/shared-doc/shared-doc.module').then(m => m.SharedDocModule) },
    { path: 'change-password', canActivate: [AuthGuard], loadChildren: () => import('./pages/change-password/change-password.module').then(m => m.ChangePasswordModule) },
    { path: 'my-form-status', canActivate: [AuthGuard], loadChildren: () => import('./pages/my-forms-status/my-form-status.module').then(m => m.MyFormStatusModule) },
    { path: 'verify-email/:token', component: VerifyEmailComponent },
    { path: 'payment-confirmation/:session_id/:data', component: PaymentConfirmationComponent },
    { path: 'pdf-generation/:formID/:token', loadChildren: () => import('./pages/pdf-generation/pdf-generation.module').then(m => m.PdfGenerationModule) },
    { path: 'payment-method', component: PaymentMethodComponent },
    { path: 'cover-page', loadChildren: () => import('./pages/cover-page/cover-page.module').then(m => m.CoverPageModule) },
    { path: 'faith', loadChildren: () => import('./pages/faith/faith.module').then(m => m.FaithModule), data: { title: 'Free Catholic Will for Leaving a Legacy With Faith & Legal Expertise', description: 'Create a free will for leaving a legacy that supports charity and strengthens faith. Arrange your estate to make a meaningful impact on future generations.' } },
    { path: 'health', loadChildren: () => import('./pages/healthcare/healthcare.module').then(m => m.HealthcareModule), data: { title: 'Free Catholic Healthcare Directive for Future Medical Decisions', description: 'Protect your wishes with a free Catholic Healthcare Directive. Ensure your medical care aligns with your faith and your voice is heard in critical situations.' } },
    { path: 'parents', loadChildren: () => import('./pages/parents/parents.module').then(m => m.ParentsModule), data: { title: 'Protect Your Children with a Catholic Will by Catholic Legacy', description: 'Protect your kids with a Catholic Will. Designate guardianship and ensure your children are cared for by someone who shares your values. Learn more on website.' } },
    { path: 'pets', loadChildren: () => import('./pages/pets/pets.module').then(m => m.PetsModule), data: { title: 'Protect Your Pets With a Catholic Will for Pet Care Today', description: 'Secure your pets are cared for in your absence. Create a Catholic Will for Pet Care to choose guardians and secure your furry family members. Check our page now.' } },
    { path: 'will', loadChildren: () => import('./pages/will/will.module').then(m => m.WillModule), data: { title: 'Create Your Free Will Online with Catholic Legacy Guidance', description: 'Easily create a free, In-depth will that reflects your values. Protect your estate and your loved ones with Catholic Legacy’s online tool. Visit webpage to know more.' } },
    { path: 'estate', loadChildren: () => import('./pages/estate/estate.module').then(m => m.EstateModule), data: { title: 'Leave a Lasting Legacy with a Catholic Estate planning tool', description: 'Get your free Catholic Will with our catholic estate planning tool vetted by estate attorneys. Create a legally sound will that aligns with your faith. ' } },
    { path: 'funeral', loadChildren: () => import('./pages/funeral/funeral.module').then(m => m.FuneralModule), data: { title: 'Free Catholic Funeral Mass Planning Tool for Comfort & Guidance', description: 'Respect your loved one’s faith with Catholic Legacy’s free catholic funeral mass planning tool. Get step-by-step guidance for funeral Mass, and Catholic burials.' } },
    { path: 'planning', loadChildren: () => import('./pages/planning/planning.module').then(m => m.PlanningModule), data: { title: 'Pre-Plan Your Catholic Funeral with  Catholic funeral planning tool', description: 'Ensure your final wishes are respected with our free Catholic funeral planning tool. Document your preferences for a meaningful funeral that honors your faith.' } },
    { path: 'future', loadChildren: () => import('./pages/future/future.module').then(m => m.FutureModule), data: { title: 'Free Catholic Healthcare Directive for Faithful Medical Care', description: 'Create a free Catholic Healthcare Directive to ensure your faith guides critical medical decisions, honoring your wishes for the Sacraments, care, and prayer.' } },
    { path: 'press', loadChildren: () => import('./pages/press/press.module').then(m => m.PressModule) },
    { path: 'parish', loadChildren: () => import('./pages/parish/parish.module').then(m => m.ParishModule), data: { title: 'Parish Funeral Management Tool with Catholic Legacy', description: 'Automate Funeral admin for Catholic parishes with Catholic Legacy’s free Parish Funeral Management Tool. Save time and streamline pastoral support during difficult times.' } },
    {
        path: '**',
        canActivate: [LowercaseUrlGuard],
        data: {
            title: "Page not found - 404",
        },
        component: NotFoundComponent
    },
];
@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
