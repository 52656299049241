<ng-container *ngIf="isLoading">
    <div class="loader text-center">
        <div class="spinner-border text-warning" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</ng-container>
<div class="modal-header">
    <h1>Catholic Legacy Feedback Survey</h1>
    <button type="button" style="float: left;" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
</div>
<div class="modal-body">
    <div class="section header">
        <p>Thank you for taking the time to participate in the Catholic Legacy Feedback Survey! Your
            input is incredibly valuable as we continue to improve our platform and ensure it meets the
            needs of everyone looking to create Wills, Healthcare Directives, and Funeral Plans. The
            information you provide will help us refine our tools and services, and we greatly appreciate
            your feedback.</p>
    </div>
    <div>
        <div class="accordion" id="accordionExample">
            <div class="accordion-item" *ngFor="let section of questions;let i = index">
                <h2 class="accordion-header">
                    <button class="accordion-button" type="button" [class.collapsed]="section.isCollapsed"
                        (click)="toggleAccordion(section)">
                        <span>{{section.section}}</span>
                    </button>
                </h2>
                <div [attr.id]="'collapse' + section._id + i" class="accordion-collapse collapse"
                    [class.show]="!section.isCollapsed" [attr.aria-labelledby]="section._id"
                    data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        <div class="question-wrap">
                            <div *ngFor="let question of section.items" class="question">
                                <span class="label">{{ question.label }}</span>

                                <div *ngIf="question.type === 'rating'">
                                    <div class="star-rating">
                                        <ng-container *ngFor="let star of [].constructor(maxWillRating); let i = index">
                                            <span class="star" (click)="setRating(i + 1, question.id)"
                                                [class.filled]="isFilled(i + 1, question.id)">
                                                ★
                                            </span>
                                        </ng-container>
                                        <span class="first-star">({{ question.starStart }})</span>
                                        <span class="last-star">({{ question.starEnd }})</span>
                                    </div>
                                </div>

                                <div *ngIf="question.type === 'textarea'">
                                    <textarea [(ngModel)]="question.response" (keydown)="checkLength(question)"
                                        [attr.maxlength]="question.id=='question21' ? 201 : null"></textarea>
                                    <div class="errorMsg" *ngIf="showMaxLengthError && question.id=='question21'">
                                        Maximum character limit of 200 characters reached.
                                    </div>
                                </div>
                                <div *ngIf="question.type === 'radio'" class="radio-style">
                                    <ng-container *ngFor="let option of question.options">
                                        <label class="radio-block radio-button-block">
                                            <input type="radio" [value]="option.id" [name]="question.id"
                                                [id]="option.id" (keydown)="blockArrowKeyNavigation($event)"
                                                (change)="explanationQuestionChange(question)"
                                                [(ngModel)]="question.response">
                                            <label [for]="option.id"><span class="radio-label">{{ option.label
                                                    }}</span></label>
                                        </label>
                                    </ng-container>
                                </div>
                                <div *ngIf="question.id === 'question6' && question.response === 'maybe_question6'">
                                    <input class="othersInput" type="text" placeholder="Please explain"
                                        [(ngModel)]="question.explanation">
                                </div>

                                <div *ngIf="question.id === 'question8' && question.response === 'other_question8'">
                                    <input class="othersInput" type="text" placeholder="Please specify"
                                        [(ngModel)]="question.explanation">
                                </div>

                                <div *ngIf="question.id === 'question9' && question.response === 'inferior_question9'">
                                    <input class="othersInput" type="text" placeholder="Please explain"
                                        [(ngModel)]="question.explanation">
                                </div>

                                <div
                                    *ngIf="question.id === 'question10' && question.response === 'other-explain_question10'">
                                    <input class="othersInput" type="text" placeholder="Please explain"
                                        [(ngModel)]="question.explanation">
                                </div>

                                <div *ngIf="question.id === 'question12' && question.response === 'no_question12'">
                                    <input class="othersInput" type="text" placeholder="Please explain"
                                        [(ngModel)]="question.explanation">
                                </div>

                                <div *ngIf="question.id === 'question13' && question.response === 'no_question13'">
                                    <input class="othersInput" type="text" placeholder="Please explain"
                                        [(ngModel)]="question.explanation">
                                </div>

                                <div *ngIf="question.id === 'question15' && question.response === 'yes_question15'">
                                    <input class="othersInput" type="text" placeholder="Please explain"
                                        [(ngModel)]="question.explanation">
                                </div>

                                <div *ngIf="question.id === 'question17' && question.response === 'other_question17'">
                                    <input class="othersInput" type="text" placeholder="Please specify"
                                        [(ngModel)]="question.explanation">
                                </div>

                                <div *ngIf="question.id === 'question18' && question.response === 'no_question18'">
                                    <input class="othersInput" type="text" placeholder="Please explain"
                                        [(ngModel)]="question.explanation">
                                </div>

                                <div *ngIf="question.id === 'question23' && question.response === 'no_question23'">
                                    <input class="othersInput" type="text" placeholder="Please explain"
                                        [(ngModel)]="question.explanation">
                                </div>

                                <div *ngIf="question.id === 'question24' && question.response === 'no_question24'">
                                    <input class="othersInput" type="text" placeholder="Please explain"
                                        [(ngModel)]="question.explanation">
                                </div>

                                <div *ngIf="question.id === 'question26' && question.response === 'no_question26'">
                                    <input class="othersInput" type="text" placeholder="Please explain"
                                        [(ngModel)]="question.explanation">
                                </div>

                                <div *ngIf="question.id === 'question27' && question.response === 'no_question27'">
                                    <input class="othersInput" type="text" placeholder="Please explain"
                                        [(ngModel)]="question.explanation">
                                </div>

                                <div *ngIf="question.id === 'question29' && question.response === 'no_question29'">
                                    <input class="othersInput" type="text" placeholder="Please explain"
                                        [(ngModel)]="question.explanation">
                                </div>
                                <div *ngIf="question.id === 'question30' && question.response === 'no_question30'">
                                    <input class="othersInput" type="text" placeholder="Please explain"
                                        [(ngModel)]="question.explanation">
                                </div>
                                <div *ngIf="question.id === 'question32' && question.response === 'no_question32'">
                                    <input class="othersInput" type="text" placeholder="Please explain"
                                        [(ngModel)]="question.explanation">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="section">
        <h3>Thank You!</h3>
        <p>Thank you for taking the time to complete our survey. Your feedback is incredibly valuable
            to us and helps us improve the Catholic Legacy platform to better serve your needs.</p>
        <p>If you have any additional thoughts or questions, feel free to reach out to us
            <a>info&#64;catholiclegacy.com</a>. We appreciate your participation and look forward to
            continuing
            to support you in creating your Catholic legacy.
        </p>
    </div>

</div>
<div class="modal-footer">
    <div class="left-btn-container">
        <!-- <button type="button" class="btn btn-secondary confirmationModalBtn" (click)="clearSurveyForm()">Clear</button> -->
    </div>
    <div class="right-btn-container">
        <button type="button" class="btn-close" style="display:none;" data-bs-dismiss="modal" #cancelSurveyModal
            aria-label="Close"></button>
        <!-- <button type="button" class="btn btn-secondary confirmationModalBtn cancelButton"  data-bs-dismiss="modal">CANCEL</button>&nbsp; -->
        <button type="button" class="btn btn-secondary confirmationModalBtn cancelButton" (click)="onCancel()"
            data-bs-dismiss="modal">CANCEL</button>&nbsp;
        <button type="button" class="btn btn-primary confirmationModalBtn sbmtButton" (click)="surveySubmit()">SUBMIT
            SURVEY</button>
    </div>
</div>