import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Subject } from 'rxjs';
import { SECRET_KEY } from '../_config/api.config';
import { DOCUMENT, isPlatformServer } from '@angular/common';

declare var forge: any;
var CryptoJS = require("crypto-js");
@Injectable({
    providedIn: 'root'
})
export class CommonService {
    domDirectionLTR = true;

    //For Add new product Steps
    public eventObservable = new Subject<any>();
    public event: any = {};

    //For access profile name from any component
    public profileName = new Subject<string>();

    //For access notification count from any component
    public updatedNotificationCount = new Subject<string>();

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        @Inject(DOCUMENT) private document: any
    ) { }

    private logoutModalTrigger = new Subject<void>();
    logoutModal$ = this.logoutModalTrigger.asObservable();

    private surveyModalTrigger = new Subject<void>();
    surveyModalTrigger$ = this.surveyModalTrigger.asObservable();

    public encryptData(data: any) {
        try {
            var encryptedData = CryptoJS.AES.encrypt(data, SECRET_KEY);
            return encryptedData.toString();
        } catch (e) {
            console.log(e);
        }
    }

    public decryptData(encryptedData: string) {
        if (!encryptedData) {
            return null;
        }
        try {
            const decodedEncryptedData = decodeURIComponent(encryptedData);
            const decryptedBytes = CryptoJS.AES.decrypt(decodedEncryptedData, SECRET_KEY);
            const decryptedString = decryptedBytes.toString(CryptoJS.enc.Utf8);
            return JSON.parse(decryptedString)
        } catch (e) {
            console.error("Error parsing decrypted data:", e);
        }
    }

    //For Add new product Steps
    public setEvent(event: string, data: any) {
        this.event = { 'event': event, 'data': data };
        this.eventObservable.next(this.event);
    }

    public downloadFile(data, title?) {
        var a = this.document.createElement("a");
        a.setAttribute("download", title);
        a.setAttribute("href", data);
        this.document.body.appendChild(a);
        a.click();
        this.document.body.removeChild(a);
    }

    localStorageSet(key: string, value: any) {
        if (!isPlatformServer(this.platformId)) {
            localStorage.setItem(key, value);
        }
    }

    localStorageGet(key) {
        if (!isPlatformServer(this.platformId)) {
            return localStorage.getItem(key);
        }
        return null;
    }

    localStorageRemove(key) {
        if (!isPlatformServer(this.platformId)) {
            localStorage.removeItem(key);
        }
    }

    localStorageClear() {
        if (!isPlatformServer(this.platformId)) {
            localStorage.clear();
        }
    }


    // Method to trigger the modal
    triggerLogoutModal() {
        this.logoutModalTrigger.next();
    }

    // Method to trigger the modal
    triggerSurveyModal() {
        this.surveyModalTrigger.next();
    }
}
