import { AfterViewInit, Component, EventEmitter, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { URLS } from 'src/app/_config/api.config';
import { ApiService } from 'src/app/services/api-data.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';

interface Question {
    id: string;           // Unique identifier for the question
    label: string;        // The question text
    response: string;     // The response to the question
    type: 'textarea' | 'rating' | 'radio'; // Type of question
    rating?: number;      // Rating for scale questions
    options?: any;   // Options for radio questions
    starStart?: string,
    starEnd?: string,
    explanation?: string,
}

@Component({
    selector: 'app-survey-form',
    templateUrl: './survey-form.component.html',
    styleUrls: ['./survey-form.component.scss']
})
export class SurveyFormComponent implements OnInit {

    showWillSection: boolean = false;
    showHealthSection: boolean = false;
    showFuneralSection: boolean = false;
    showOtherSection: boolean = false;
    showwillExplainSection: any;
    showhealthExplainSection: any;
    showfuneralExplainSection: any;
    testimonialPermession: boolean = false;
    showadditionalExplainSection: any;
    showMaxLengthError = false;
    isApiCalled = false;

    isLoading = false;
    data: any;
    surveyId: any;
    @ViewChild('cancelSurveyModal') cancelSurveyModal;
    private subscription: Subscription = new Subscription()

    generalRating: number = 0; // The current rating
    maxGeneralRating: number = 5; // Maximum rating
    willRating: number = 0; // The current rating
    maxWillRating: number = 5; // Maximum rating
    healthRating: number = 0; // The current rating
    maxHealthRating: number = 5; // Maximum rating
    funeralRating: number = 0; // The current rating
    maxFuneralRating: number = 5; // Maximum rating


    generalRatingChange: EventEmitter<number> = new EventEmitter<number>();
    willRatingChange: EventEmitter<number> = new EventEmitter<number>();
    healthRatingChange: EventEmitter<number> = new EventEmitter<number>();
    funeralRatingChange: EventEmitter<number> = new EventEmitter<number>();

    questions: { section: string; sectionId: number, isCollapsed: boolean, items: Question[] }[] = [
        {
            section: 'General Experience Questions',
            sectionId: 1,
            isCollapsed: true,
            items: [
                {
                    id: 'question1',
                    label: 'What prompted you to create your Will/Healthcare Directive/Funeral Plan?',
                    response: '',
                    type: 'textarea'
                },
                {
                    id: 'question2',
                    label: 'On a scale of 1 to 5, how would you rate your overall experience with Catholic Legacy?',
                    response: '',
                    type: 'rating',
                    starStart: 'Very Dissatisfied',
                    starEnd: 'Very Satisfied'
                },
                {
                    id: 'question3',
                    label: 'What did you like about the Catholic Legacy experience?',
                    response: '',
                    type: 'textarea'
                },
                {
                    id: 'question4',
                    label: 'What did you dislike about the Catholic Legacy experience?',
                    response: '',
                    type: 'textarea'
                },
                {
                    id: 'question5',
                    label: 'What challenges were you facing regarding end-of-life planning before you found Catholic Legacy?',
                    response: '',
                    type: 'textarea'
                },
                {
                    id: 'question6',
                    label: 'Would you recommend Catholic Legacy to a friend or family member?',
                    response: '',
                    type: 'radio',
                    explanation: '',
                    options: [
                        { id: 'yes_question6', label: 'Yes' },
                        { id: 'no_question6', label: 'No' },
                        { id: 'maybe_question6', label: 'Probably (Please explain)' }
                    ]
                }
            ]
        },
        {
            section: 'Platform Use and Comparison',
            sectionId: 2,
            isCollapsed: true,
            items: [
                {
                    id: 'question7',
                    label: 'Before using Catholic Legacy, had you created a Will, Funeral Plan, or Healthcare Directive for yourself?',
                    response: '',
                    type: 'radio',
                    options: [
                        { id: 'yes_question7', label: 'Yes' },
                        { id: 'no_question7', label: 'No' }
                    ]
                },
                {
                    id: 'question8',
                    label: 'If yes, how did you create it previously?',
                    response: '',
                    type: 'radio',
                    explanation: '',
                    options: [
                        { id: 'another-online-paid_question8', label: 'Another Online Platform - Paid' },
                        { id: 'another-online-free_question8', label: 'Another Online Platform - Free' },
                        { id: 'through-attorney', label: 'Through an Attorney' },
                        { id: 'other_question8', label: 'Other (Please specify)' }
                    ]
                },
                {
                    id: 'question9',
                    label: 'If selected another online platform, free or paid, how does Catholic Legacy compare to other platforms you\'ve used?',
                    response: '',
                    type: 'radio',
                    explanation: '',
                    options: [
                        { id: 'superior_question9', label: 'Superior' },
                        { id: 'similar_question9', label: 'Similar' },
                        { id: 'inferior_question9', label: 'Inferior (Please explain)' }
                    ]
                },
                {
                    id: 'question10',
                    label: 'If yes to already created one, what made you choose Catholic Legacy over your previous method?',
                    response: '',
                    type: 'radio',
                    explanation: '',
                    options: [
                        { id: 'faith-alignment_question10', label: 'Faith alignment' },
                        { id: 'free-cost_question10', label: 'Free (cost)' },
                        { id: 'recommendation_question10', label: 'Recommendation to me' },
                        { id: 'other-explain_question10', label: 'Other (Please explain)' }
                    ]
                }
            ]
        },
        {
            section: 'Spiritual and Emotional Impact',
            sectionId: 3,
            isCollapsed: true,
            items: [
                {
                    id: 'question11',
                    label: "How important was it to you that Catholic Legacy's tools are aligned with Catholic teachings?",
                    response: '',
                    type: 'rating',
                    starStart: 'Not important',
                    starEnd: 'Very Important'
                },
                {
                    id: 'question12',
                    label: 'Has using Catholic Legacy encouraged you to have conversations with your family or friends about your future plans?',
                    response: '',
                    type: 'radio',
                    explanation: '',
                    options: [
                        { id: 'yes_question12', label: 'Yes' },
                        { id: 'discussed_question12', label: 'I had already discussed my future plans before using Catholic Legacy.' },
                        { id: 'no_question12', label: 'No (Please explain)' }
                    ]
                },
                {
                    id: 'question13',
                    label: 'After using Catholic Legacy, do you feel more at peace regarding your end-of-life planning?',
                    response: '',
                    type: 'radio',
                    explanation: '',
                    options: [
                        { id: 'yes_question13', label: 'Yes' },
                        { id: 'no_question13', label: 'No (Please explain)' }
                    ]
                }
            ]
        },
        {
            section: 'Platform Reliability and Accessibility',
            sectionId: 4,
            isCollapsed: true,
            items: [
                {
                    id: 'question14',
                    label: 'How would you rate the overall design of the Catholic Legacy platform?',
                    response: '',
                    type: 'rating',
                    starStart: 'Very Poor',
                    starEnd: 'Excellent'
                },
                {
                    id: 'question15',
                    label: 'Did you experience any technical difficulties while using Catholic Legacy?',
                    response: '',
                    type: 'radio',
                    explanation: '',
                    options: [
                        { id: 'yes_question15', label: 'Yes (Please explain)' },
                        { id: 'no_question15', label: 'No' }
                    ]
                },
                {
                    id: 'question16',
                    label: "How would you rate the platform's ease of use for individuals with limited technology experience?",
                    response: '',
                    type: 'rating',
                    starStart: 'Very Poor',
                    starEnd: 'Very Accessible'
                },
                {
                    id: 'question17',
                    label: 'Did you access Catholic Legacy from a mobile device, tablet, or desktop computer?',
                    response: '',
                    type: 'radio',
                    explanation: '',
                    options: [
                        { id: 'mobile-device_question17', label: 'Mobile device' },
                        { id: 'tablet_question17', label: 'Tablet' },
                        { id: 'desktop-computer_question17', label: 'Desktop computer' },
                        { id: 'other_question17', label: 'Other' }
                    ]
                },
                {
                    id: 'question18',
                    label: 'Was the information easy to navigate and understand?',
                    response: '',
                    type: 'radio',
                    explanation: '',
                    options: [
                        { id: 'yes_question18', label: 'Yes' },
                        { id: 'no_question18', label: 'No (Please explain)' }
                    ]
                },
            ]
        },
        {
            section: 'Feedback on Tools, Support, and Guidance',
            sectionId: 6, // Adjust as needed
            isCollapsed: true,
            items: [
                {
                    id: 'question32',
                    label: 'Did you find the instructions and support resources (e.g., FAQ, Contact Us, Tooltips) on Catholic Legacy helpful?',
                    response: '',
                    type: 'radio',
                    explanation: '',
                    options: [
                        { id: 'yes_question32', label: 'Yes' },
                        { id: 'no_question32', label: 'No (Please explain)' }
                    ]
                },
                {
                    id: 'question33',
                    label: 'Did you require customer support, and if so, how was your experience?',
                    response: '',
                    type: 'textarea'
                }
            ]
        },
        {
            section: 'Additional Comments and Testimonial',
            sectionId: 3,
            isCollapsed: true,
            items: [
                {
                    id: 'question19',
                    label: 'Are there any features or resources you wish Catholic Legacy offered but doesn’t currently?',
                    response: '',
                    type: 'textarea'
                },
                {
                    id: 'question20',
                    label: 'Do you have any additional comments or suggestions for Catholic Legacy?',
                    response: '',
                    type: 'textarea'
                },
                {
                    id: 'question21',
                    label: 'Would you like to provide a testimonial about your experience with Catholic Legacy?',
                    response: '',
                    type: 'textarea'
                },
                {
                    id: 'question22',
                    label: 'Do we have your permission to feature your testimonial on the Catholic Legacy platform?',
                    response: '',
                    type: 'radio',
                    options: [
                        { id: 'yes_question22', label: 'Yes' },
                        { id: 'no_question22', label: 'No' }
                    ]
                }
            ]
        },
        {
            section: 'Feedback on the Will Form',
            sectionId: 6,
            isCollapsed: true,
            items: [
                {
                    id: 'question23',
                    label: 'Did the form cover all necessary aspects of your estate planning?',
                    response: '',
                    type: 'radio',
                    explanation: '',
                    options: [
                        { id: 'yes_question23', label: 'Yes' },
                        { id: 'no_question23', label: 'No (Please explain)' }
                    ]
                },
                {
                    id: 'question24',
                    label: 'Were you encouraged to leave a charitable bequest in your Will?',
                    response: '',
                    type: 'radio',
                    explanation: '',
                    options: [
                        { id: 'yes_question24', label: 'Yes' },
                        { id: 'no_question24', label: 'No (Please explain)' }
                    ]
                },
                {
                    id: 'question25',
                    label: 'What could be improved to make the Will form clearer or more useful?',
                    response: '',
                    type: 'textarea'
                }
            ]
        },
        {
            section: 'Feedback on the Healthcare Directive Form',
            sectionId: 4,
            isCollapsed: true,
            items: [
                {
                    id: 'question26',
                    label: 'Did the form help you understand the Catholic Church’s teachings on end-of-life care and medical decisions?',
                    response: '',
                    type: 'radio',
                    explanation: '',
                    options: [
                        { id: 'yes_question26', label: 'Yes' },
                        { id: 'no_question26', label: 'No (Please explain)' }
                    ]
                },
                {
                    id: 'question27',
                    label: 'Do you feel more prepared for future healthcare decisions after using this form?',
                    response: '',
                    type: 'radio',
                    explanation: '',
                    options: [
                        { id: 'yes_question27', label: 'Yes' },
                        { id: 'no_question27', label: 'No (Please explain)' }
                    ]
                },
                {
                    id: 'question28',
                    label: 'What improvements, if any, would you suggest for the Catholic Healthcare Directive form?',
                    response: '',
                    type: 'textarea'
                }
            ]
        },
        {
            section: 'Feedback on the Funeral Guide',
            sectionId: 5,
            isCollapsed: true,
            items: [
                {
                    id: 'question29',
                    label: 'Did the form help you reflect on and document your preferences for a Catholic funeral?',
                    response: '',
                    type: 'radio',
                    explanation: '',
                    options: [
                        { id: 'yes_question29', label: 'Yes' },
                        { id: 'no_question29', label: 'No (Please explain)' }
                    ]
                },
                {
                    id: 'question30',
                    label: 'Did you find the options for funeral readings, music, and other elements helpful?',
                    response: '',
                    type: 'radio',
                    explanation: '',
                    options: [
                        { id: 'yes_question30', label: 'Yes' },
                        { id: 'no_question30', label: 'No (Please explain)' }
                    ]
                },
                {
                    id: 'question31',
                    label: 'What could be added to the Wake, Funeral and Cemetery Guide to enhance the planning process?',
                    response: '',
                    type: 'textarea'
                }
            ]
        }
    ];
    responses: { [key: string]: string } = {};

    @Input() isVisible: boolean;

    ngOnInit(): void {
        const token = this.commonService.localStorageGet('accessToken');
        // if ((token || this.commonService.localStorageGet('accessToken'))) {
        //     this.getData();
        // }
    }


    ngOnChanges(changes: SimpleChanges): void {
        if (changes['isVisible'] && changes['isVisible'].currentValue === true) {
            this.isApiCalled = true;
            this.getData();
        }
    }


    constructor(private dataService: ApiService,
        private toastr: ToastrService,
        private commonService: CommonService
    ) { }

    getData() {

        if (!this.isLoading) {
            this.isLoading = true;
            this.subscription.add(this.dataService.get(URLS.surveyForm).subscribe(
                res => {
                    if (res['code'] === 200) {
                        this.isLoading = false;
                        this.data = res['data']
                        if (res['data'] && res['data']['_id']) {
                            this.surveyId = res['data']['_id']
                            this.updateQuestionsFromSurveyData(this.data.surveyData);
                        }

                    } else {
                        this.isLoading = false;
                    }
                },
                error => {
                    this.isLoading = false;
                    this.toastr.error(error['message'], 'Error')
                }

            ));
        }
    }

    surveySubmit() {
        if (this.showMaxLengthError)
            return;
        const surveyData = this.questions.map(section => {
            return {
                sectionTitle: section.section,   // Set the section title
                sectionId: section.sectionId.toString(),  // Create formId based on sectionId
                sectionData: section.items.map(item => {
                    // Prepare the answer object
                    let answer = { response: item.response };

                    // Add explanation only if it exists and is not empty
                    if (item.explanation && item.explanation.trim()) {
                        answer['explanation'] = item.explanation;
                    }

                    return {
                        question: item.label,     // Use question label as "question"
                        fieldKey: item.id,        // Use question id as "fieldKey"
                        answer: [answer]            // Set the response and explanation if applicable
                    };
                })
            };
        });

        let params = {
            // "formId": string,
            "rating": parseInt(this.questions[0].items[1].response),
            "surveyData": surveyData
        }
        let isClearData = true;
        this.questions.forEach((section, i) => {
            section.items.forEach((question) => {
                if (question.response != '' && question.response != null && question.response != undefined) {
                    isClearData = false;
                    return;
                }
            })
            // if(isClearData){
            //     params['isClearForm']=true,
            //     params['rating']=null, 
            //     params['surveyData']=null;
            // }
        })

        if (this.surveyId) {
            params['surveyId'] = this.surveyId;
        }
        this.isLoading = true;
        this.subscription.add(this.dataService.post(URLS.surveyForm, params).subscribe(
            res => {
                if (res['code'] === 200) {
                    this.cancelSurveyModal.nativeElement.click();
                    this.isLoading = false;
                }
            },
            error => {
                this.isLoading = false;
                this.toastr.error(error['message'], 'Error');
            }
        ));

    }

    updateQuestionsFromSurveyData(surveyData: any[]) {
        surveyData.forEach(section => {
            section.sectionData.forEach(dataItem => {
                // Loop through all sections and items in the `questions` array
                this.questions.forEach(questionSection => {
                    questionSection.items.forEach(questionItem => {
                        // Match the question using the fieldKey (id)
                        if (questionItem.id === dataItem.fieldKey) {
                            // Update the response and explanation from the surveyData
                            questionItem.response = dataItem.answer[0].response;

                            // Check if explanation exists in the surveyData and update
                            if (dataItem.answer[0].explanation) {
                                questionItem.explanation = dataItem.answer[0].explanation;
                            }
                        }
                    });
                });
            });
        });
    }


    // Set star rating
    setRating(rating: number, questionId: string) {
        const question = this.questions.flatMap(q => q.items).find(q => q.id === questionId);
        if (question && question.type === 'rating') {
            question.response = rating.toString(); // Store the rating as a string
        }
    }

    toggleAccordion(data: any) {
        data.isCollapsed = !data.isCollapsed; // Toggle the clicked accordion
    }

    // Check if the star is filled based on rating
    isFilled(rating: number, questionId: string): boolean {
        const question = this.questions.flatMap(q => q.items).find(q => q.id === questionId);
        return question?.response && parseInt(question.response) >= rating;
    }

    explanationQuestionChange(question) {
        if (question.explanation) {
            question.explanation = ''
        }
    }


    blockArrowKeyNavigation(event: KeyboardEvent): void {
        // Prevent arrow keys (up, down, left, right) from changing radio selection
        if (event.key === 'ArrowDown' || event.key === 'ArrowUp' || event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
            event.preventDefault();
        }
    }


    clearSurveyForm() {
        this.questions.forEach((section, i) => {
            section.items.forEach((question) => {
                question.response = '';
                if (question.explanation)
                    question.explanation = '';
            })
        })
    }

    checkLength(question) {
        if (question.id == 'question21' && question.response && question.response.length == 201) {
            this.showMaxLengthError = true;
        } else if (question.id == 'question21') {
            this.showMaxLengthError = false;
        }
    }

    onCancel() {
        // if(this.data && this.data.surveyData)
        // this.updateQuestionsFromSurveyData(this.data.surveyData);
    }

}
