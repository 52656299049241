// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    apiUrl: 'https://catholiclw-api.dev.brainvire.net',
    webUrl: 'https://catholiclw-cust.dev.brainvire.net',
    SENTRY_AUTH_TOKEN: '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'
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
